import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import React from 'react'
import { SupplierBranchEntity } from '../../../graphql/aminTypes'
import { formatAddressToDisplay } from '../../../services/dataToString/formatAddressToDisplay'
import { FlagIcon } from '../../../components/svgIcons/FlagIcon'
import { ServiceCategoryTranslation, sortServiceCategoryEntityFn } from '../../../services/types/service-category.type'

export const supplierBranchDataDefinition: TableDefinitionTypes<SupplierBranchEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      headerStyle: { width: '80px' }
    },
    setupIsFinal: {
      name: 'Finálně nastaveno',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      headerStyle: { width: '80px' }
    },
    pou: { name: 'POU' },
    specification: { name: 'Upřesnění' },
    address: { name: 'Adresa' },
    ordersContact: { name: 'Kontakt na objednávky' },
    note: { name: 'Poznámka' },
    services: { name: 'Nabízené služby' },
    flags: { name: 'Příznaky' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '70px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isActive: x => x.isActive,
    setupIsFinal: x => x.setupIsFinal,
    specification: x => x.specification ?? '',
    pou: x => x.pou?.name ?? '',
    address: x => (x.address ? formatAddressToDisplay(x.address) : ''),
    ordersContact: x =>
      x.ordersContact
        ? `${x.ordersContact.name ?? ''} ${x.ordersContact.email ? `<${x.ordersContact.email}>` : ''}  ${
            x.ordersContact.phone ? `<${x.ordersContact.phone}>` : ''
          }`
        : '',
    note: x => x.note,
    services: x => {
      if (!x.branchServices || x.branchServices.length <= 0) return ''
      const sorted = [...x.branchServices].sort(sortServiceCategoryEntityFn)
      return sorted.map(s => ServiceCategoryTranslation[s.serviceCategory]).join(', ')
    },

    flags: x => (
      <>
        <FlagIcon className="icon-cash" key="COD" value={!!x.paymentCapabilities?.find(y => y === 'CASH')} desc={'Platba v hotovosti'} />
        <FlagIcon className="fe fe-trash-2" key="icz" value={!!x.icz} useEmptyIcon desc={`ICZ: ${x.icz}`} />
      </>
    ),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'editBranch',
        icon: 'fe-edit',
        title: 'Upravit pobočku'
      })
      return ret
    }
  }
}
