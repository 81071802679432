/* istanbul ignore file */

import { MutationResult, QueryResult } from '@apollo/client'

export function isQueryLoading(query: QueryResult | MutationResult<any> | undefined): boolean {
  return !!query && query.loading
}

export function hasQueryData(query: QueryResult | MutationResult<any> | undefined): boolean {
  return !!query && !query.loading && !query.error && query.data && Object.keys(query.data).length > 0
}

export function hasQueryArrayData(query: QueryResult | undefined): boolean {
  let queryName = undefined
  if (hasQueryData(query)) {
    // @ts-ignore
    queryName = Object.keys(query.data)[0]
  }
  // @ts-ignore
  return queryName && query.data[queryName] && query.data[queryName].length > 0
}

export function isAnyQueryLoading(...query: (QueryResult | undefined | MutationResult<any>)[]) {
  for (const q of query) if (q && q.loading) return true
  return false
}
