import PageCard from '../../components/Cards/pageCard'
import React, { useContext, useState } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'
import { useCustomerListQuery } from './services/customerListQuery'
import { customersDataDefinition } from './services/customersDataDefinition'
import { useSendPwdSetupEmailForB2bCustomerMutation } from './services/sendPwdSetupEmailForB2bCustomerMutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { ModalContext } from '../../components/Modal/ModalProvider'
import AllowedPaymentMethodsEditor from './components/AllowedPaymentMethodsEditor'
import { useFilterOptions4CustomSelectCreator } from '../../services/bootstrapTable/useFilterOptions4CustomSelectCreator'
import B2bPriceListRow from './components/B2bProductsList/B2bPriceListRow'
import { Customer, CustomerResponse } from '../../graphql/aminTypes'
import { PaymentMethod, paymentMethodsList } from '../../services/types/payment-method'
import { useNavigate } from 'react-router-dom'
import { useUpdateCustomerMutation } from '../CustomerDetail/CustomerEditor/mutations/udate-customer.mutation'
import { useHighlightRowAfterReturn, useReturnBackWithHighlight } from '../../services/pageHooks/highlightRowAfterReturn'
import { httpBuildQueryString } from '../../services/functions/httpBuildQuery'
import highlightOrders from '../OrderList/services/highlightOrders'

export default function CustomerList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const [updateCustomerMut, updateCustomerMR] = useUpdateCustomerMutation()
  const [rowToExpand, setRowToExpand] = useState<number>()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<CustomerResponse>(customersDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(
    ['id', 'companyName', 'firstname', 'lastname', 'email', 'phone', 'isActive', 'isResellCompany', 'flags'],
    nameForGqlTranslation,
    { defaultPageSize: 100, alternativeOrderByName: true }
  )
  const { createReturnUrl, moveScrollPosition, rowClassForHighlightedRow } = useHighlightRowAfterReturn()

  const customerListQR = useCustomerListQuery(gqlQueryParams, moveScrollPosition)
  const [sendPwdSetupEmail, sendPwdSetupEmailQR] = useSendPwdSetupEmailForB2bCustomerMutation()
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(
    pageConfiguration,
    undefined,
    undefined,
    customerListQR.data?.customerList.total
  )
  const filterOptions = useFilterOptions4CustomSelectCreator()
  filterOptions.addFilterOption(
    'isActive',
    [
      { key: 'true', value: 'Aktivní' },
      { key: 'false', value: 'Neaktivní' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )
  filterOptions.addFilterOption(
    'isResellCompany',
    [
      { key: 'true', value: 'Ano' },
      { key: 'false', value: 'Ne' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )
  filterOptions.addFilterOption('flags', [{ value: 'IS_USING_ADVANCE_ACCOUNTS', label: 'Používá zákaznické účty' }])

  const columnDefinition = generateColumnListForBootstrapTable({
    filterOptions4CustomSelectCreator: filterOptions,
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    actionsReference: { sendPwdRetrievalEmail: sendPwdRetrievalEmailHandler },
    columnAction: handleTableClick
  })
  let b2bCustomersData: any[] = []
  if (customerListQR.data && customerListQR.data.customerList.total > 0) b2bCustomersData = mapDataArrayToTable(customerListQR.data.customerList.items)

  return (
    <PageCard showLoader={isAnyQueryLoading(customerListQR, sendPwdSetupEmailQR, updateCustomerMR)} headerTitle={'Registrovaní zákazníci'}>
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                rowClasses={rowClassForHighlightedRow(highlightOrders)}
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                data={b2bCustomersData}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChange}
                {...paginationTableProps}
                expandRow={b2bRowExpand(rowToExpand)}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>
    </PageCard>
  )

  function sendPwdRetrievalEmailHandler(row: any) {
    if (!row.id) return
    const setSendPwdPromise = sendPwdSetupEmail({ variables: { loginId: row.loginId } })
    processPageOperation({
      promise: setSendPwdPromise,
      successMessage: 'Email byl odeslán na uživatele',
      successAction: async result => {
        await customerListQR.refetch()
      }
    })
  }

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex === 17) return
    if (columnIndex !== 9) {
      const fwQueryString = httpBuildQueryString({
        customerId: row.id,
        returnUrl: createReturnUrl(row.id)
      })
      navigate('/customer/editor' + fwQueryString)
      return
    }
    const rowData = customerListQR.data?.customerList.items?.find(x => x.id === row.id)
    showModal({
      modalContent: (
        <AllowedPaymentMethodsEditor
          b2bCustomerId={row.id}
          allowedPaymentsMethods={rowData!.allowedPaymentMethods!}
          primaryPaymentMethod={rowData!.primaryPaymentMethod}
          handleFormSubmit={updateAllowedPaymentMethodsHandler}
        />
      ),
      title: 'Editovat povolené platební metody',
      hideFooter: true
    })
  }

  function updateAllowedPaymentMethodsHandler(b2bCustomerId: number, data: Record<string, boolean>) {
    hideModal()
    const customer = customerListQR.data?.customerList.items.find(x => x.id === b2bCustomerId)
    if (!data || !customer) return
    const allowedPaymentMethods = Object.keys(data)
      .filter(x => paymentMethodsList.includes(x as PaymentMethod))
      .filter(x => data[x])
    const promise = updateCustomerMut({
      variables: {
        data: {
          updatedAt: customer.updatedAt,
          id: b2bCustomerId,
          primaryPaymentMethod: data['primaryPaymentMethod'],
          allowedPaymentMethods
        }
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Povolené platební metody byly aktualizovány'
    })
  }

  function b2bRowExpand(rowId: number | undefined) {
    return {
      renderer: function orderRowExpand(row: any) {
        if (!customerListQR.data?.customerList?.items || rowId === undefined) return
        const payload = customerListQR.data.customerList.items.find(x => +x.id === rowId)
        if (!payload) return
        return <B2bPriceListRow b2bId={rowId} companyInfo={row} />
      },
      onlyOneExpanding: true,
      showExpandColumn: true,
      expanded: [rowId],
      expandByColumnOnly: true,
      onExpand: d => setRowToExpand(d.id)
    }
  }
}
