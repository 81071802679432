import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'
import AddressPicker from '../../components/formComponents/AddressPicker/AddressPicker'
import PageCard from '../../components/Cards/pageCard'
import SupplierBranchPicker from '../common/picker-components/SupplierBranchPicker/SupplierBranchPicker'
import ServiceCategoryPicker from '../common/picker-components/ServiceCategoryPicker/ServiceCategoryPicker'
import { getServiceType, ServiceCategoryType } from '../../services/types/service-category.type'
import { useDistanceForServiceQuery } from './queries/distance-for-service.query'
import { NetworkStatus } from '@apollo/client'
import { InfoAlert } from '../../components/formComponents/alert'
import DistanceMap from './components/DistanceMap/DistanceMap'
import { PriceCalculatorResponse } from '../../graphql/aminTypes'

export default function Calculator() {
  const formMethods = useForm()
  const address = formMethods.watch('customerAddress')
  const distanceQR = useDistanceForServiceQuery()
  const isAddressPicked = address && address['lng']
  const [distance, setDistance] = React.useState<PriceCalculatorResponse>()
  const serviceCategory = formMethods.watch('serviceCategory')
  const serviceType = getServiceType(serviceCategory)
  let supportSupplierCategory: ServiceCategoryType = serviceType === 'DELIVERY' ? 'MATERIAL_SELLER' : 'WASTE_TERMINAL'
  if (serviceCategory === 'TRANSPORT_OF_CONCRETE') supportSupplierCategory = 'CONCRETE_SELLER'

  return (
    <PageCard headerTitle="Výpočet cen za služby" showLoader={distanceQR.networkStatus === NetworkStatus.refetch}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-6">
              <h2>Adresa zákazníka:</h2>
              <AddressPicker name={'customerAddress'} required registerOptions={{ required: 'Poloha nebyla nalezena.' }} className="bg-orange-lt" />
              <h2>Služba</h2>
              <fieldset className="form-fieldset bg-pink-lt">
                <div className="row mb-2">
                  <div className="col-md-12">
                    <ServiceCategoryPicker
                      disabled={!isAddressPicked}
                      omitCategories={['LOAD_TRANSPORT', 'WASTE_TERMINAL', 'TRANSPORT_OF_DEK_MACHINERY', 'MATERIAL_SELLER', 'CONCRETE_SELLER']}
                    ></ServiceCategoryPicker>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-12">
                    <SupplierBranchPicker name={'supplierSupplierBranchId'} label={'Dodavatel'} disabled={!isAddressPicked} serviceCategory={serviceCategory} />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-12">
                    <SupplierBranchPicker
                      required={false}
                      name={'supportSupplierBranchId'}
                      label={serviceType === 'DELIVERY' ? 'Prodejna materiálu' : 'Koncovka'}
                      disabled={!serviceType}
                      serviceCategory={supportSupplierCategory}
                    />
                  </div>
                </div>
              </fieldset>
              <div className="row">
                <div className="col-md-6">
                  <button type="submit" className="btn btn-primary">
                    Vypočítat
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {address && (
                <DistanceMap
                  leftTopBound={distance?.leftTopBound}
                  rightBottomBound={distance?.rightBottomBound}
                  encodedGeometry={distance?.encodedGeometry}
                  customerPosition={address ? [address['lat'], address['lng']] : undefined}
                />
              )}
            </div>
          </div>
        </form>
        {distance && <InfoAlert message={`Vzdálenost: ${distance.distanceTotal / 1000} km`} />}
      </FormProvider>
    </PageCard>
  )

  function handleFormSubmit(data: any) {
    const params = {
      type: serviceType,
      ...data
    }
    distanceQR.refetch(params).then(result => {
      if (result.data.distanceForService) setDistance(result.data.distanceForService)
    })
  }
}
