import React from 'react'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import ToggleField from '../../../components/formComponents/ToggleField'

export function SettingsNode() {
  return (
    <>
      <div className="row mb-2 mt-3">
        <div className="col-md-6">
          <ToggleField name={'settings.useSelfAsSupplierForService'} label={'Využívat sebe jako dodavatele služby'} />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-3">
          <FormField name={'settings.minimumPriceOfTransport'} label={'Minimální cena za dopravu'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.manipulationsPerOrder'} label={'Počet manipulací na zakázku'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.includedKmInMinimumTransportPrice'} label={'Km v ceně minimální dopravy'} type={inputType.number} valueAsNumber />
        </div>
      </div>
    </>
  )
}
