import React from 'react'
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet'
import { SupplierMapItem } from '../SupplierSupplierMap'
import { serviceCategoryColors } from '../services/service-category-colors'
import { ServiceCategoryTranslation } from '../../../services/types/service-category.type'
import { createMultiServiceIcon } from '../../../services/maps/create-multiservice-icon'

export default function SuppliersMap({ supplierMapItems }: { supplierMapItems: SupplierMapItem[] }) {
  return (
    <MapContainer center={[50.0741169, 15.3004478]} zoom={8} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
        maxZoom={18}
        attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
      />
      {supplierMapItems.map((supplier, idx) => (
        <Marker
          key={idx}
          position={[supplier.gps.lat, supplier.gps.lng]}
          icon={createMultiServiceIcon(supplier.serviceCategory.map(sc => serviceCategoryColors[sc]))}
        >
          <Popup>
            {supplier.name} <br /> {supplier.serviceCategory.map(c => ServiceCategoryTranslation[c]).join(', ')}
          </Popup>

          <Tooltip>
            {supplier.name} <br /> {supplier.serviceCategory.map(c => ServiceCategoryTranslation[c]).join(', ')}
          </Tooltip>
        </Marker>
      ))}
    </MapContainer>
  )
}
