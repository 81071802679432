import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'

const suppliersCountQuery = gql`
  query suppliersCount($offset: Int, $limit: Int, $id: String, $order: String, $name: String, $orpName: String, $disabled: String, $regionCode: String, $flags: SupplierFlagEnum) {
    suppliersCount(offset: $offset, limit: $limit, id: $id, order: $order, name: $name,orpName: $orpName, disabled: $disabled, regionCode: $regionCode, flags: $flags)
  }
`

export function useSuppliersCountQuery(params: IGqlPagingQueryParams) {
  return useQuery<{ suppliersCount: number }>(suppliersCountQuery, { fetchPolicy: 'network-only', variables: params })
}
