import SelectField from '../../../../components/formComponents/SelectField'
import { ServiceCategoryType } from '../../../../services/types/service-category.type'
import { useGetBranchListByServiceQuery } from './queries/get-branch-list-by-service.query'

export default function SupplierBranchPicker({
  disabled,
  serviceCategory,
  label,
  name,
  required = true
}: {
  label?: string
  disabled?: boolean
  serviceCategory?: ServiceCategoryType
  name: string
  required?: boolean
}) {
  const supplierBranchesQR = useGetBranchListByServiceQuery(serviceCategory, { skip: !serviceCategory || !!disabled })
  const data =
    supplierBranchesQR.data?.getBranchListByService.map(x => ({
      label: `${x.supplier!.name}${x.specification ? ` - ${x.specification}` : ''} (${x.address?.city})`,
      value: x.id
    })) || []
  return (
    <SelectField label={label} name={name} registerOptions={{ required: required }} required={required} disabled={disabled} optionsData={data}></SelectField>
  )
}
