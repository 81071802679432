import React from 'react'
import { FormComponentBaseInput } from './components/FormComponentBaseInput'
import { IFormComponentBaseInput } from './components/IFormComponentBaseInput'
import { RegisterOptions, useFormContext } from 'react-hook-form'
import { IStringObject } from '../../services/types/IStringObject'
import { createErrorMessages } from '../../services/formsServices/createErrorMessages'
import classnames from 'classnames'

interface IFormInput extends IFormComponentBaseInput {
  type: inputType
  isPositive?: boolean
  isDecimal?: boolean
  is2Decimal?: boolean
  isWeight?: boolean
  isMoney?: boolean
  defaultValue?: string | number
  autofocus?: boolean
  maxLength?: number
  notEditable?: boolean
  valueAsNumber?: boolean | undefined
}

export enum inputType {
  text = 'text',
  number = 'number',
  email = 'email',
  password = 'password',
  phone = 'phone'
}

// Define a custom type that extends RegisterOptions
export type CustomRegisterOptions = Omit<RegisterOptions, 'valueAsNumber'> & {
  valueAsNumber?: boolean | undefined
}

export default function FormField(fields: IFormInput) {
  const formMethods = useFormContext()
  let extraOptions: IStringObject = {}
  if (fields.isPositive) extraOptions['min'] = 0
  if (fields.isMoney) {
    extraOptions['step'] = 0.01
    extraOptions['min'] = 0
  }
  if (fields.isDecimal) extraOptions['step'] = 0.1
  if (fields.is2Decimal) extraOptions['step'] = 0.01
  if (fields.isWeight) extraOptions['step'] = 0.25
  if (fields.type === inputType.phone) extraOptions['pattern'] = '^\\+?[0-9 ]+$'
  const options: CustomRegisterOptions = { ...fields.registerOptions, valueAsNumber: fields.valueAsNumber }
  return (
    <FormComponentBaseInput {...fields}>
      <input
        readOnly={fields.notEditable}
        contentEditable={!fields.notEditable}
        autoFocus={fields.autofocus}
        defaultValue={fields.defaultValue ?? ''}
        placeholder={fields.placeholder}
        {...formMethods.register(fields.name, createErrorMessages(options))}
        name={fields.name}
        id={fields.name}
        maxLength={fields.maxLength}
        type={fields.type === inputType.phone ? inputType.text : fields.type}
        className={classnames('form-control', formMethods.formState.errors[fields.name] ? 'is-invalid' : '')}
        autoComplete="off"
        {...extraOptions}
        disabled={fields.disabled}
      />
    </FormComponentBaseInput>
  )
}
