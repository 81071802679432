import { gql, useQuery } from '@apollo/client'
import { SupplierBranchEntity } from '../../../../../graphql/aminTypes'
import { ServiceCategoryType } from '../../../../../services/types/service-category.type'

const getBranchListByServiceQuery = gql`
  query getBranchListByService($serviceCategory: ServiceCategory!) {
    getBranchListByService(serviceCategory: $serviceCategory) {
      id
      specification
      supplier {
        id
        name
      }
      address {
        id
        street
        city
      }
    }
  }
`

export function useGetBranchListByServiceQuery(serviceCategory?: ServiceCategoryType, opts?: { skip: boolean }) {
  return useQuery<{ getBranchListByService: SupplierBranchEntity[] }>(getBranchListByServiceQuery, {
    fetchPolicy: 'network-only',
    variables: { serviceCategory },
    skip: !!opts?.skip
  })
}
