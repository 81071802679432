import React, { useEffect } from 'react'
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from 'react-leaflet'
import { LatLngExpression, LatLngLiteral } from 'leaflet'
import { decodePolylineFromGeometry } from '../../../../services/maps/decode-polyline-from-geometry'

// Helper component to update bounds
function UpdateBounds({ bounds }: { bounds: [[number, number], [number, number]] }) {
  const map = useMap()

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds)
    }
  }, [bounds, map])

  return null
}

export default function DistanceMap({
  customerPosition,
  encodedGeometry,
  leftTopBound,
  rightBottomBound
}: {
  customerPosition?: LatLngExpression
  leftTopBound?: LatLngLiteral
  rightBottomBound?: LatLngLiteral
  encodedGeometry?: string
}) {
  let polylinePositions: LatLngExpression[] | undefined = undefined
  if (encodedGeometry) {
    polylinePositions = decodePolylineFromGeometry(encodedGeometry, false)
  }

  const mapProps =
    leftTopBound && rightBottomBound
      ? {}
      : {
          center: customerPosition,
          zoom: 13
        }

  return (
    <MapContainer {...mapProps} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
      <TileLayer
        url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
        maxZoom={18}
        attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
      />
      {customerPosition && (
        <Marker position={customerPosition}>
          <Popup>Lokace zákazníka</Popup>
        </Marker>
      )}
      {polylinePositions && <Polyline positions={polylinePositions} color="blue" />}
      {leftTopBound && rightBottomBound && (
        <UpdateBounds
          bounds={[
            [leftTopBound.lat, leftTopBound.lng],
            [rightBottomBound.lat, rightBottomBound.lng]
          ]}
        />
      )}
    </MapContainer>
  )
}
