import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { CapabilityType } from '../../AddOrEditSupplier/services/suppliersDetailQuery'

export const suppliersQuery = gql`
  query suppliers(
    $offset: Int
    $limit: Int
    $id: String
    $order: String
    $name: String
    $orpName: String
    $disabled: String
    $regionCode: String
    $flags: SupplierFlagEnum
  ) {
    suppliers(
      offset: $offset
      limit: $limit
      id: $id
      order: $order
      name: $name
      orpName: $orpName
      disabled: $disabled
      regionCode: $regionCode
      flags: $flags
    ) {
      id
      name
      orpName
      city
      street
      streetNumber
      zip
      gps {
        coordinates
      }
      companyIdentification
      contactPerson
      email
      phone
      containerRentPricePerDayKc
      publicHolidayFeeKc
      pathCalculationStrategy
      ourMarginPercent
      expressDeliveryFeeKc
      disabled
      autoApproveOrders
      note
      web
      priority
      regionCode
      useFlatRatePriceList
      sendOrderAllEmails
      priceListIsFinal
      allowWorkOnHolidays
      allowWorkOnSaturday
      allowWorkOnSunday
      acceptCod
      hasIcz
      capability
      loginToSuppliers {
        id
      }
    }
  }
`

export type SupplierItemType = {
  id: number
  name: string
  orpName: string
  city: string
  street: string
  streetNumber: string
  zip: string
  gps: {
    coordinates: number[]
  }
  companyIdentification: string
  contactPerson: string
  email: string
  phone: string
  containerRentPricePerDayKc: number
  publicHolidayFeeKc: number
  pathCalculationStrategy: string
  ourMarginPercent: number
  expressDeliveryFeeKc: number
  disabled: boolean
  autoApproveOrders: boolean
  useFlatRatePriceList: boolean
  loginToSuppliers: { id: number }[]
  regionCode: number
  sendOrderAllEmails: boolean
  priceListIsFinal: boolean
  note: string
  web: string
  priority: number
  allowWorkOnHolidays: boolean
  allowWorkOnSaturday: boolean
  allowWorkOnSunday: boolean
  acceptCod: boolean
  hasIcz: boolean
  capability: CapabilityType
}

export function useSuppliersQuery(params: IGqlPagingQueryParams, onCompleted: any) {
  return useQuery<{ suppliers: SupplierItemType[] }>(suppliersQuery, { fetchPolicy: 'network-only', variables: params, onCompleted })
}
