import React, { useEffect, useRef } from 'react'
import { formatAddressToDisplay } from '../../../../services/dataToString/formatAddressToDisplay'
import { coordinatesToGps } from '../../../../services/functions/coordinatesToGps'
import { OrderContentsResult } from '../../../../graphql/aminTypes'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import { MapUpdater } from '../../../../services/maps/MapUpdater'

export default function OrderMap({ orderContent }: { orderContent: OrderContentsResult }) {
  const order = orderContent.order

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <strong>Adresa realizace: </strong>
          <a href={`http://www.google.com/maps/place/${order.gps.coordinates[1]},${order.gps.coordinates[0]}`} target="_blank" rel="noopener noreferrer">
            {formatAddressToDisplay(order)} <i className="fe fe-external-link" />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MapContainer
            center={[order.gps.coordinates[1], order.gps.coordinates[0]]}
            zoom={11}
            style={{ height: '400px', width: '100%' }}
            scrollWheelZoom={false}
          >
            <MapUpdater customerGps={coordinatesToGps(order.gps)} currentSupplier={orderContent.supplier}></MapUpdater>
            <TileLayer
              url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
              maxZoom={18}
              attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
            />
            <Marker
              position={[order.gps.coordinates[1], order.gps.coordinates[0]]}
              icon={L.icon({ iconUrl: '/images/customerMapPoint.png', iconSize: [48, 48], iconAnchor: [24, 38] })}
              title="Zákazník"
            />
            {orderContent.supplier && (
              <Marker
                position={[orderContent.supplier.gps.coordinates[1], orderContent.supplier.gps.coordinates[0]]}
                icon={L.icon({ iconUrl: '/images/highlightedMapPoint.png', iconSize: [48, 48], iconAnchor: [24, 48] })}
                title={`Dodavatel ${orderContent.supplier.name}`}
              />
            )}
          </MapContainer>
        </div>
      </div>
    </>
  )
}
