import React from 'react'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import ToggleField from '../../../components/formComponents/ToggleField'

export function SettingsNode() {
  return (
    <>
      <div className="row mb-2 mt-3">
        <div className="col-md-6">
          <ToggleField name={'settings.useSelfAsSupplierForService'} label={'Využívat sebe jako dodavatele služby'} />
        </div>
      </div>
      <div className="row mb-2 mt-3">
        <div className="col-md-6">
          <ToggleField name={'settings.offersPump'} label={'Nabízí pumpu (řešit individuálně)'} />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-3">
          <strong>Beton sklápěč</strong>
        </div>
        <div className="col-md-3">
          <FormField name={'settings.minimumPriceOfTransportTruck'} label={'Minimální cena za dopravu'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.includedKmInMinimumTransportPriceTruck'} label={'Km v ceně minimální dopravy'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.manipulationsPerOrderTruck'} label={'Počet manipulací na zakázku'} type={inputType.number} valueAsNumber />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-3">
          <strong>Beton kontejner</strong>
        </div>
        <div className="col-md-3">
          <FormField name={'settings.minimumPriceOfTransportTruckContainer'} label={'Minimální cena za dopravu'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField
            name={'settings.includedKmInMinimumTransportPriceTruckContainer'}
            label={'Km v ceně minimální dopravy'}
            type={inputType.number}
            valueAsNumber
          />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.manipulationsPerOrderTruckContainer'} label={'Počet manipulací na zakázku'} type={inputType.number} valueAsNumber />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-3">
          <strong>Beton Mix</strong>
        </div>
        <div className="col-md-3">
          <FormField name={'settings.minimumPriceOfTransportTruckMix'} label={'Minimální cena za dopravu'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.includedKmInMinimumTransportPriceTruckMix'} label={'Km v ceně minimální dopravy'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.manipulationsPerOrderTruckMix'} label={'Počet manipulací na zakázku'} type={inputType.number} valueAsNumber />
        </div>
      </div>
    </>
  )
}
