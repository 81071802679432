import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import React, { useContext } from 'react'
import PageCard from '../../components/Cards/pageCard'
import SupplierServiceEnabler from '../common/components/SupplierServiceEnabler/SupplierServiceEnabler'
import { SettingsNode } from './components/SettingsNode'
import { ServiceCategoryTranslation } from '../../services/types/service-category.type'
import { TransportLinkComponent } from '../common/TransportLinkComponent/TransportLinkComponent'
import { TransportCategory } from '../../graphql/aminTypes'

const currentServiceCategory = 'TRANSPORT_OF_FUEL'
export default function SupplierServiceDeliveryOfFuel() {
  const { state } = useContext(SelectedSupplierModalContext)
  const currentServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === currentServiceCategory)
  return (
    <PageCard showLoader={false} headerTitle={ServiceCategoryTranslation[currentServiceCategory]}>
      <SupplierServiceEnabler currentServiceSetting={currentServiceSetting} serviceCategory={currentServiceCategory} settingsNode={<SettingsNode />} />
      {currentServiceSetting && (
        <>
          <h2>Dodavatelé materiálu</h2>
          <TransportLinkComponent branchServiceId={currentServiceSetting?.id} transportCategory={TransportCategory.Fuel} />
        </>
      )}
    </PageCard>
  )
}
