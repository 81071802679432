import SelectField from '../../../../components/formComponents/SelectField'
import { serviceTypeSelectOptions } from '../../../../services/types/service-type'
import { serviceCategoryOptions, ServiceCategoryType } from '../../../../services/types/service-category.type'

export default function ServiceCategoryPicker({ disabled, omitCategories }: { disabled?: boolean; omitCategories?: ServiceCategoryType[] }) {
  const options = serviceCategoryOptions.filter(x => !omitCategories?.includes(x.value))

  return (
    <SelectField
      label={'Typ služby'}
      name={'serviceCategory'}
      registerOptions={{ required: true }}
      required
      disabled={disabled}
      optionsData={options}
    ></SelectField>
  )
}
