import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { SupplierItemType } from './suppliersQuery'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'
import { pathCalculationStrategyOptions } from '../../../services/types/pathCalculationStrategyOptions'
import { formatPhoneToDisplay } from '../../../services/dataToString/formatPhoneToDisplay'
import React from 'react'
import { FlagIcon } from '../../../components/svgIcons/FlagIcon'
import { AuthorisationAlertSvg } from '../../../components/svgIcons/AuthorisationAlertSvg'

const pathCalculationStrategyBadgeColors = {
  'Paušál + 2 cesty': 'bg-warning',
  'Paušál + 4 cesty': 'bg-warning',
  '2 cesty': 'bg-info',
  '4 cesty': 'bg-success',
  '5 cest': 'bg-danger'
}

export const supplierListDataDefinition: TableDefinitionTypes<SupplierItemType> = {
  columnDefinition: {
    id: { hidden: true },
    disabled: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      headerStyle: { width: '50px' },
      align: ColumnAlign.center,
      remoteSort: true,
      filterType: ColumnFilterTypes.customSelect
    },
    regionCode: {
      name: 'Číslo oblasti',
      align: ColumnAlign.center,
      filterType: ColumnFilterTypes.customText,
      remoteSort: true,
      headerStyle: { width: '140px' },
      defaultRemoteSort: 'asc'
    },
    orpName: { name: 'ORP', filterType: ColumnFilterTypes.customText, remoteSort: true },
    name: { name: 'Jméno', filterType: ColumnFilterTypes.customText, remoteSort: true },
    address: { name: 'Adresa', headerStyle: { minWidth: '150px' } },
    contactPerson: { name: 'Kontaktní osoba' },
    email: { name: 'E-mail' },
    phone: { name: 'Telefon', headerStyle: { minWidth: '100px' } },
    pathCalculationStrategy: {
      name: 'Způsob výpočtu trasy',
      align: ColumnAlign.center,
      columnFormatterType: ColumnFormatterTypes.showInBadge,
      badgeColours: pathCalculationStrategyBadgeColors
    },
    pathCalculationStrategyRaw: { hidden: true },
    ourMarginPercent: { name: 'Marže', align: ColumnAlign.center, remoteSort: true },
    flags: { name: 'Příznaky', headerStyle: { width: '275px' }, filterType: ColumnFilterTypes.customSelect },
    gps: { name: 'gps', hidden: true },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '160px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    orpName: x => x.orpName,
    name: x => x.name,
    disabled: x => !x.disabled,
    address: x => (
      <a
        href={`http://www.google.com/maps/place/${x.gps.coordinates[1]},${x.gps.coordinates[0]}`}
        target="_blank"
        rel="noopener noreferrer"
      >{`${x.city}, ${x.street} ${x.streetNumber}`}</a>
    ),
    contactPerson: x => x.contactPerson,
    regionCode: x => x.regionCode,
    email: x => (x.email ? x.email.split(';').join('; ') : ''),
    phone: x => <a href={'tel:' + x.phone}> {formatPhoneToDisplay(x.phone)} </a>,
    pathCalculationStrategyRaw: x => x.pathCalculationStrategy,
    pathCalculationStrategy: x =>
      (x.useFlatRatePriceList ? 'Paušál + ' : '') + pathCalculationStrategyOptions.find(p => p.value === x.pathCalculationStrategy)?.label,
    expressDeliveryFeeKc: x => moneyToLocaleString(x.expressDeliveryFeeKc, 'CZK'),
    publicHolidayFeeKc: x => moneyToLocaleString(x.publicHolidayFeeKc, 'CZK'),
    containerRentPricePerDayKc: x => moneyToLocaleString(x.containerRentPricePerDayKc, 'CZK'),
    ourMarginPercent: x => x.ourMarginPercent + '%',
    flags: x => (
      <>
        <FlagIcon className={x.priority === 1 ? 'icon-1' : x.priority === 2 ? 'icon-2' : 'icon-3'} value={true} desc={'Priorita'} />
        <>
          {x.capability.length > 0 ? (
            <span title={'Oprávnění: ' + x.capability.join(' ')} className={'mr-2'}>
              {' '}
              <AuthorisationAlertSvg color={'black'} />
            </span>
          ) : (
            <span title={'Nemá oprávnění'} className={'fe-disabled-icon mr-2'}>
              {' '}
              <AuthorisationAlertSvg color={'black'} />
            </span>
          )}
        </>
        <FlagIcon className="icon-key" value={x.loginToSuppliers.length > 0} desc={'Metrák Partner přístup'} />
        <FlagIcon className="fe fe-fast-forward fe-larger" value={x.autoApproveOrders} desc={'Automatické schválení administrátorem'} />
        <FlagIcon className="fe fe-mail fe-larger" value={x.sendOrderAllEmails} desc={'Zasílat emaily pro přistavení a odvoz kontejnerů'} />
        <FlagIcon className="fe fe-dollar-sign fe-larger" value={x.priceListIsFinal} desc={'Ceník je finální'} />
        <FlagIcon
          className="icon-calendar-plus"
          value={x.allowWorkOnHolidays || x.allowWorkOnSaturday || x.allowWorkOnSunday}
          desc={'Pracuje o víkendech/svátcích'}
        />
        <FlagIcon className="icon-cash" value={x.acceptCod} desc={'Dobírka'} />
        <FlagIcon className="fe fe-trash-2 fe-larger" value={x.hasIcz} desc={'Oprávněný nakládat s odpady'} />
        <FlagIcon className="fe fe-type fe-larger" value={!!x.note} desc={'Poznámka: ' + x.note} useEmptyIcon />
        {!!x.web && x.web !== 'http://' && (
          <a href={x.web} target="_blank" rel="noopener noreferrer">
            <FlagIcon className="fe fe-globe fe-larger" value={!!x.web} desc={x.web} />
          </a>
        )}
      </>
    ),
    gps: x => x.gps.coordinates,
    actions: x => {
      const ret: IRowAction[] = []
      if (x.email) {
        ret.push({
          type: RowActionType.codeAction,
          value: 'createNewLogin',
          icon: 'fe-log-in',
          title: 'Zaregistrovat přístup'
        })
      }
      if (x.disabled)
        ret.push({
          type: RowActionType.codeAction,
          value: 'enableSupplier',
          icon: 'fe-arrow-up',
          title: 'Povolit dodavatele'
        })
      else
        ret.push({
          type: RowActionType.codeAction,
          value: 'disableSupplier',
          icon: 'fe-arrow-down',
          title: 'Nepoužívat dodavatele'
        })
      ret.push({
        type: RowActionType.codeAction,
        value: 'editSupplier',
        icon: 'fe-edit',
        title: 'Upravit'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'duplicateSupplier',
        icon: 'fe-copy',
        title: 'Duplikovat'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteSupplier',
        icon: 'fe-x',
        title: 'Odstranit dodavatele'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'showOnMap',
        icon: 'fe-map-pin',
        title: 'Zobrazit na mapě'
      })
      return ret
    }
  }
}
