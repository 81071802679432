import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { SupplierSupplierListResponse } from '../../../graphql/aminTypes'
import { ServiceCategoryType } from '../../../services/types/service-category.type'

export const supplierSupplierListQuery = gql`
  query supplierSupplierList($name: String, $serviceCategories: [ServiceCategory!]) {
    supplierSupplierList(name: $name, serviceCategories: $serviceCategories) {
      total
      items {
        id
        isActive
        name
        branches {
          gps {
            lng
            lat
          }
          address {
            city
            street
            streetNumber
            id
            zip
          }
          branchServices {
            serviceCategory
          }
        }
      }
    }
  }
`

export function useSupplierSupplierListQuery(params?: { name?: string; serviceCategories?: ServiceCategoryType[] }) {
  return useQuery<{ supplierSupplierList: SupplierSupplierListResponse }>(supplierSupplierListQuery, {
    fetchPolicy: 'network-only',
    variables: params
  })
}
