import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route, RouterProvider, createBrowserRouter } from 'react-router-dom'
import LoadingContainer from './components/LoadingContainer'
import MasterPage from './components/MasterPage/masterPage'
import { MasterPageForAdminUser, MasterPageForSuppliers } from './components/MasterPage/masterPageHOC'

import OrderList from './scenesAdmin/OrderList/OrderList'
import AddOrEditOrder from './scenesAdmin/AddOrEditOrder/AddOrEditOrder'
import SupplierList from './scenesAdmin/SupplierList/SupplierList'
import AddOrEditSupplier from './scenesAdmin/AddOrEditSupplier'
import TerminalList from './scenesAdmin/TerminalList/TerminalList'
import AddOrEditTerminal from './scenesAdmin/AddOrEditTerminal'
import LoginList from './scenesAdmin/LoginList/LoginList'
import ContainerList from './scenesAdmin/ContainerList/ContainerList'
import ForgottenPassword from './ScenesLogin/ForgottenPassword/ForgottenPassword'
import LoginComponent from './ScenesLogin/LoginComponent/LoginComponent'
import SetupPassword from './ScenesLogin/SetupPassword/SetupPassword'
import HandleActionFromEmail from './ScenesLogin/HandleActionFromEmail'
import SupplierOrderList from './scenesSupplier/OrderList/OrderList'
import ResponseList from './scenesAdmin/ResponseList/ResponseList'
import FileUpload from './scenesSupplier/FileUpload'
import OrderDetail from './scenesAdmin/OrderDetail/OrderDetail'
import CustomerList from './scenesAdmin/CustomerList/CustomerList'
import SearchWholeDb from './scenesAdmin/SearchWholeDb/SearchWholeDb'
import Dashboard from './scenesAdmin/Dashboard/Dashboard'
import ProductList from './scenesAdmin/ProductList/ProductList'
import { ToastContainer } from 'react-toastify'
import Accounting from './scenesAdmin/Accounting/Accounting'
import ProductWasteList from './ScenesAdminPriceLists/ProductWasteList/ProductWasteList'
import ProductMaterialComponents from './ScenesAdminPriceLists/ProductMaterialComponents/ProductMaterialComponents'
import ProductConcreteComponents from './ScenesAdminPriceLists/ProductContreteComponents/ProductConcreteComponents'
import ProductMaterialList from './ScenesAdminPriceLists/ProductMaterialList/ProductMaterialList'
import SupplierSupplierList from './ScenesAdminPriceLists/SupplierSupplierList/SupplierSupplierList'
import SupplierBranchList from './ScenesAdminPriceLists/SupplierBranchList/SupplierBranchList'
import SupplierContainerList from './ScenesAdminPriceLists/SupplierContainerList/SupplierContainerList'
import SupplierServiceLoadTransport from './ScenesAdminPriceLists/SupplierServiceLoadTransport/SupplierServiceLoadTransport'
import SupplierServiceWasteCollectionSolid from './ScenesAdminPriceLists/SupplierServiceWasteCollectionSolid/SupplierServiceWasteCollectionSolid'
import SupplierServiceWasteCollectionLiquid from './ScenesAdminPriceLists/SupplierServiceWasteCollectionLiquid/SupplierServiceWasteCollectionLiquid'
import ProductContainerList from './ScenesAdminPriceLists/ProductContainerList/ProductContainerList'
import SupplierServiceWasteCollectionAdr from './ScenesAdminPriceLists/SupplierServiceWasteCollectionAdr/SupplierServiceWasteCollectionAdr'
import SupplierServiceWasteCollectionScrap from './ScenesAdminPriceLists/SupplierServiceWasteCollectionScrap/SupplierServiceWasteCollectionScrap'
import SupplierServiceWasteTerminal from './ScenesAdminPriceLists/SupplierServiceWasteTerminal/SupplierServiceWasteTerminal'
import SupplierServiceMaterialPriceList from './ScenesAdminPriceLists/SupplierServiceMaterialPriceList/SupplierServiceMaterialPriceList'
import SupplierSupplierMap from './ScenesAdminPriceLists/SupplierSupplierMap/SupplierSupplierMap'
import SupplierServiceTransportOfMaterial from './ScenesAdminPriceLists/SupplierServiceTransportOfMaterial/SupplierServiceTransportOfMaterial'
import SupplierServiceDeliveryOfFuel from './ScenesAdminPriceLists/SupplierServiceTransportOfFuel/SupplierServiceDeliveryOfFuel'
import SupplierServiceDeliveryOfConcrete from './ScenesAdminPriceLists/SupplierServiceTransportOfConcrete/SupplierServiceDeliveryOfConcrete'
import SupplierServiceTransportOfDekMachinery from './ScenesAdminPriceLists/SupplierServiceTransportOfDekMachinery/SupplierServiceTransportOfDekMachinery'
import SupplierServiceConcreteSeller from './ScenesAdminPriceLists/SupplierServiceConcreteSeller/SupplierServiceConcreteSeller'
import Calculator from './ScenesAdminPriceLists/Calculator/Calculator'
import Customer from './scenesAdmin/CustomerDetail/Customer'
import SupplierMap from './scenesAdmin/PointsMap/SupplierMap'

/* istanbul ignore next */
function EmptyPage() {
  return <></>
}

/* istanbul ignore next */
function Loading() {
  return (
    <MasterPage isForPublic>
      <div className="my-3 my-md-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <LoadingContainer showLoader />
            </div>
          </div>
        </div>
      </div>
    </MasterPage>
  )
}

const router = createBrowserRouter([
  { path: '/reload', element: <MasterPageForAdminUser Component={EmptyPage} /> },

  { path: '/', element: <LoginComponent /> },
  { path: '/forgotten-password', element: <ForgottenPassword /> },
  { path: '/password-setup', element: <SetupPassword /> },
  { path: '/handle-action-from-email', element: <HandleActionFromEmail /> },

  { path: '/accounting/transaction-pairing', element: <MasterPageForAdminUser Component={Accounting} /> },
  { path: '/accounting/group-orders', element: <MasterPageForAdminUser Component={Accounting} /> },
  { path: '/accounting/group-invoices', element: <MasterPageForAdminUser Component={Accounting} /> },
  { path: '/dashboard', element: <MasterPageForAdminUser Component={Dashboard} /> },
  { path: '/search-whole-db', element: <MasterPageForAdminUser Component={SearchWholeDb} /> },
  { path: '/order-list', element: <MasterPageForAdminUser Component={OrderList} /> },
  { path: '/edit-order', element: <MasterPageForAdminUser Component={AddOrEditOrder} /> },
  { path: '/add-order', element: <MasterPageForAdminUser Component={AddOrEditOrder} /> },
  { path: '/supplier-list', element: <MasterPageForAdminUser Component={SupplierList} /> },
  { path: '/supplier-map', element: <MasterPageForAdminUser Component={SupplierMap} /> },
  { path: '/add-supplier', element: <MasterPageForAdminUser Component={AddOrEditSupplier} /> },
  { path: '/edit-supplier', element: <MasterPageForAdminUser Component={AddOrEditSupplier} /> },
  { path: '/terminal-list', element: <MasterPageForAdminUser Component={TerminalList} /> },
  { path: '/add-terminal', element: <MasterPageForAdminUser Component={AddOrEditTerminal} /> },
  { path: '/edit-terminal', element: <MasterPageForAdminUser Component={AddOrEditTerminal} /> },
  { path: '/login-list', element: <MasterPageForAdminUser Component={LoginList} /> },
  { path: '/container-list', element: <MasterPageForAdminUser Component={ContainerList} /> },
  { path: '/response', element: <MasterPageForAdminUser Component={ResponseList} /> },
  { path: '/order-detail', element: <MasterPageForAdminUser Component={OrderDetail} /> },
  { path: '/customer-list', element: <MasterPageForAdminUser Component={CustomerList} /> },
  { path: '/customer/editor', element: <MasterPageForAdminUser Component={Customer} /> },
  { path: '/customer/advance-accounts', element: <MasterPageForAdminUser Component={Customer} /> },
  { path: '/product-list', element: <MasterPageForAdminUser Component={ProductList} /> },

  { path: '/configuration/container-list', element: <MasterPageForAdminUser Component={ProductContainerList} /> },
  { path: '/configuration/waste-list', element: <MasterPageForAdminUser Component={ProductWasteList} /> },
  { path: '/configuration/material-list', element: <MasterPageForAdminUser Component={ProductMaterialList} /> },
  { path: '/configuration/material-components', element: <MasterPageForAdminUser Component={ProductMaterialComponents} /> },
  { path: '/configuration/concrete-components', element: <MasterPageForAdminUser Component={ProductConcreteComponents} /> },
  { path: '/configuration/supplier-map', element: <MasterPageForAdminUser Component={SupplierSupplierMap} /> },
  { path: '/configuration/supplier-list', element: <MasterPageForAdminUser Component={SupplierSupplierList} /> },
  { path: '/configuration/supplier-branch-list', element: <MasterPageForAdminUser Component={SupplierBranchList} /> },
  { path: '/configuration/supplier/containers', element: <MasterPageForAdminUser Component={SupplierContainerList} /> },
  { path: '/configuration/supplier/load-transport', element: <MasterPageForAdminUser Component={SupplierServiceLoadTransport} /> },
  { path: '/configuration/supplier/transport-of-material', element: <MasterPageForAdminUser Component={SupplierServiceTransportOfMaterial} /> },
  { path: '/configuration/supplier/transport-of-fuel', element: <MasterPageForAdminUser Component={SupplierServiceDeliveryOfFuel} /> },
  { path: '/configuration/supplier/transport-of-concrete', element: <MasterPageForAdminUser Component={SupplierServiceDeliveryOfConcrete} /> },
  { path: '/configuration/supplier/transport-of-dek-machinery', element: <MasterPageForAdminUser Component={SupplierServiceTransportOfDekMachinery} /> },
  { path: '/configuration/supplier/waste-collection-solid', element: <MasterPageForAdminUser Component={SupplierServiceWasteCollectionSolid} /> },
  { path: '/configuration/supplier/waste-collection-liquid', element: <MasterPageForAdminUser Component={SupplierServiceWasteCollectionLiquid} /> },
  { path: '/configuration/supplier/waste-collection-adr', element: <MasterPageForAdminUser Component={SupplierServiceWasteCollectionAdr} /> },
  { path: '/configuration/supplier/waste-collection-scrap', element: <MasterPageForAdminUser Component={SupplierServiceWasteCollectionScrap} /> },
  { path: '/configuration/supplier/waste-terminal', element: <MasterPageForAdminUser Component={SupplierServiceWasteTerminal} /> },
  { path: '/configuration/supplier/material-seller', element: <MasterPageForAdminUser Component={SupplierServiceMaterialPriceList} /> },
  { path: '/configuration/supplier/concrete-seller', element: <MasterPageForAdminUser Component={SupplierServiceConcreteSeller} /> },
  { path: '/configuration/calculator', element: <MasterPageForAdminUser Component={Calculator} /> },

  { path: '/supplier/order-list', element: <MasterPageForSuppliers Component={SupplierOrderList} /> },
  { path: '/supplier/file-upload', element: <MasterPageForSuppliers Component={FileUpload} /> }
])

export default function AppRouterSetup() {
  return (
    <Suspense fallback={<Loading />}>
      <ToastContainer autoClose={8000} limit={3} position="top-center" hideProgressBar={false} />
      <RouterProvider router={router} />
    </Suspense>
  )
}
