import React, { useContext } from 'react'
import { useGetZpoDetailQuery } from './query/getZpoDetailQuery'
import { zpoDetailContent } from './services/zpoDetailContent'
import { ModalContext } from '../../../../components/Modal/ModalProvider'
import { HtmlDataList } from '../../../../components/HtmlDataList'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../../components/LoadingContainer'
import { useGenerateZpo } from './mutation/generateZpo'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useReGenerateZpo } from './mutation/reGenerateZpo'
import { usePerformZpoEmailDelivery } from './mutation/performZpoEmailDelivery'
import ZpoEditor from './ZpoEditor'
import { useDownloadS3File } from '../../../../hooks/useGetDownloadUrl'
import { useMarkManualDelivery } from './mutation/mark-manual-delivery.mutation'

export default function ZpoMainComponent({ orderContentId, zpoNote }: { orderContentId: number; zpoNote?: any }) {
  const { showModal, hideModal } = useContext(ModalContext)
  const { downloadS3FileHandler, s3FileLoading } = useDownloadS3File()
  const getZpoDetailQR = useGetZpoDetailQuery(orderContentId)
  const [generateZpoMut, generateZpoQR] = useGenerateZpo(orderContentId)
  const [reGenerateZpoMut, reGenerateZpoQR] = useReGenerateZpo(orderContentId)
  const [performZpoEmailDeliveryMut, performZpoEmailDeliveryQR] = usePerformZpoEmailDelivery(orderContentId)
  const [markManualDeliveryMut, markManualDeliveyQR] = useMarkManualDelivery(orderContentId)

  const zpoGenerated = !!getZpoDetailQR.data?.getZpoDetail?.s3key

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(generateZpoQR, reGenerateZpoQR, performZpoEmailDeliveryQR, getZpoDetailQR, markManualDeliveyQR)}>
      <fieldset className="form-fieldset">
        <HtmlDataList label="ZPO" data={zpoDetailContent(getZpoDetailQR.data?.getZpoDetail, zpoNote)} />
        {getZpoDetailQR.data?.getZpoDetail?.s3key && (
          <div className="row">
            <div className="col-6">
              <button
                type={'button'}
                disabled={s3FileLoading}
                className="btn btn-secondary btn-ghost-azure"
                onClick={() => downloadS3FileHandler(getZpoDetailQR.data!.getZpoDetail.s3key!)}
              >
                ZPO ke stažení
              </button>
            </div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-12">
            <div className="d-flex flex-wrap justify-content-md-center">
              <button type="button" className="btn btn-primary mr-1 mt-1" onClick={showUpdateZpoHandler}>
                Upravit
              </button>
              <button
                type="button"
                className="btn btn-primary mr-1  mt-1"
                disabled={!getZpoDetailQR.data?.getZpoDetail?.wasteType}
                onClick={generateZpoHandler}
              >
                {!zpoGenerated ? 'Vygenerovat ZPO' : 'Smazat a vytvořit nové'}
              </button>
              <button
                type="button"
                className="btn btn-primary mr-1  mt-1"
                disabled={!zpoGenerated || !getZpoDetailQR.data?.getZpoDetail?.notificationEmails}
                onClick={sendZpoByEmailHandler}
              >
                Odeslat ZPO na email
              </button>
              <button type="button" className="btn btn-secondary  mt-1" onClick={markManualDeliveryHandler}>
                Odesláno ručně
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </LoadingContainer>
  )

  function showUpdateZpoHandler() {
    showModal({
      hideFooter: true,
      title: 'Úprava ZPO',
      modalContent: <ZpoEditor orderContentId={orderContentId} onFinish={hideModal} />
    })
  }

  function sendZpoByEmailHandler() {
    if (window.confirm('ZPO bude odesláno na emaily uvedené v detailu ZPO. Pokračovat?')) {
      const promise = performZpoEmailDeliveryMut({
        variables: { orderContentId: orderContentId }
      })
      processPageOperation({
        promise: promise,
        successMessage: 'ZPO bylo úspěšně odesláno na požadované emaily.'
      })
    }
  }

  function generateZpoHandler() {
    const promise = zpoGenerated
      ? reGenerateZpoMut({ variables: { orderContentId: orderContentId } })
      : generateZpoMut({ variables: { orderContentId: orderContentId } })
    processPageOperation({
      promise: promise,
      successMessage: 'ZPO bylo úspěšně vygenerováno.'
    })
  }

  function markManualDeliveryHandler() {
    const promise = markManualDeliveryMut({ variables: { orderContentId: orderContentId } })
    processPageOperation({
      promise: promise,
      successMessage: 'ZPO bylo označeno jako doručeno.'
    })
  }
}
