import React, { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { SelectedSupplierModalContext } from '../../../SelectedSupplierModal/SelectedSupplierModal'
import SelectField from '../../../../components/formComponents/SelectField'
import { truckTypeOptions } from './truck-type'
import ToggleField from '../../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { useSupplierContainerListQuery } from '../../../SupplierContainerList/queries/supplier-container-list.query'
import LoadingContainer from '../../../../components/LoadingContainer'
import { useUpsertSupplierServiceLoadTransportMutation } from './mutations/upsert-supplier-service-load-transport.mutation'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { SupplierTruckEntity } from '../../../../graphql/aminTypes'

export default function SupplierTruckEditor({
  branchServiceId,
  onClose,
  item
}: {
  item?: SupplierTruckEntity
  branchServiceId?: string
  onClose?: () => void
}) {
  const { state } = useContext(SelectedSupplierModalContext)
  const [upsertSupplierServiceLoadTransportMut, upsertSupplierServiceLoadTransportQR] = useUpsertSupplierServiceLoadTransportMutation()
  const formMethods = useForm({
    defaultValues: {
      isActive: true
    }
  })
  const supplierContainerQR = useSupplierContainerListQuery(state.selectedBranch?.id)
  useEffect(() => {
    if (item) {
      let data = {
        isActive: item.isActive,
        name: item.name,
        truckType: item.truckType,
        capacityTonne: item.capacityTonne,
        pricePerKmKc: item.pricePerKmKc,
        manipulationPriceKc: item.manipulationPriceKc,
        minimumPriceOfTransport: item.minimumPriceOfTransport,
        hasHydraulicArm: item.hasHydraulicArm,
        hydraulicArmPriceKc: item.hydraulicArmPriceKc,
        supportedCollectionServices: {},
        supportedDeliveryServices: {},
        supportedContainerTypes: {}
      }
      item.supportedCollectionServices.forEach(x => (data.supportedCollectionServices[x] = true))
      item.supportedDeliveryServices.forEach(x => (data.supportedDeliveryServices[x] = true))
      item.supportedContainerTypes.forEach(x => (data.supportedContainerTypes[x] = true))
      formMethods.reset(data)
    }
  }, [item])
  return (
    <LoadingContainer showLoader={upsertSupplierServiceLoadTransportQR.loading}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(updateTruckValues)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-2">
              <ToggleField name={'isActive'} label={'Aktivní'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3">
              <FormField type={inputType.text} name={'name'} label={'Jméno'} required />
            </div>
            <div className="col-md-3">
              <SelectField optionsData={truckTypeOptions} name={'truckType'} label={'Typ'} required />
            </div>
            <div className="col-md-3">
              <FormField type={inputType.text} name={'capacityTonne'} label={'Doložnost [t]'} valueAsNumber />
            </div>
            <div className="col-md-3">
              <FormField type={inputType.number} name={'pricePerKmKc'} label={'Kč za km'} valueAsNumber required />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3">
              <FormField type={inputType.number} name={'manipulationPriceKc'} label={'Manipulace [Kč]'} valueAsNumber />
            </div>
            <div className="col-md-3">
              <FormField type={inputType.number} name={'minimumPriceOfTransport'} label={'Minimální cena za dopravu [Kč]'} valueAsNumber />
            </div>
            <div className="col-md-3 pt-3">
              <ToggleField name={'hasHydraulicArm'} label={'Hydraulická ruka'} />
            </div>
            <div className="col-md-3">
              <FormField type={inputType.number} name={'hydraulicArmPriceKc'} label={'Kč za hydraulickou ruku'} valueAsNumber />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <h2>Odvoz</h2>
            </div>
            <div className="col-md-3">
              <h2>Rozvoz</h2>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <ToggleField name={'supportedCollectionServices.solidWaste'} label={'Tuhé odpady'} /> <br />
              <ToggleField name={'supportedCollectionServices.liquidWaste'} label={'Tekuté odpady'} /> <br />
              <ToggleField name={'supportedCollectionServices.adr'} label={'ADR'} /> <br />
              <ToggleField name={'supportedCollectionServices.scrap'} label={'Druhotné suroviny/vraky'} /> <br />
            </div>
            <div className="col-md-3">
              <ToggleField name={'supportedDeliveryServices.materialDelivery'} label={'Rozvoz materiálů'} /> <br />
              <ToggleField name={'supportedDeliveryServices.fuelDelivery'} label={'Rozvoz paliva'} /> <br />
              <ToggleField name={'supportedDeliveryServices.machinery'} label={'Rozvoz strojů'} /> <br />
            </div>
            <div className="col-md-4">
              <ToggleField name={'supportedDeliveryServices.concreteDeliveryContainer'} label={'Beton v kontejneru'} />
              <br />
              <ToggleField name={'supportedDeliveryServices.concreteDeliveryDumpTruck'} label={'Beton sklápěčka'} />
              <br />
              <ToggleField name={'supportedDeliveryServices.concreteDeliveryMix'} label={'Beton autodomíchávač'} />
              <br />
            </div>
          </div>
          <h1>Může vézt kontejnery:</h1>
          <LoadingContainer showLoader={supplierContainerQR.loading}>
            {supplierContainerQR.data?.supplierContainerList.map(container => (
              <div className="row mb-2" key={container.id}>
                <div className="col-md-5">
                  <ToggleField name={`supportedContainerTypes.${container.id}`} label={`${container.container.name} ${container.container.volumeM3}m³`} />
                </div>
              </div>
            ))}
          </LoadingContainer>
          <div className="row mb-2">
            <div className="col-md-2 offset-10">
              <button type="submit" className="btn btn-primary btn-block">
                Uložit
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function updateTruckValues(data: any) {
    data = {
      id: item?.id,
      ...data,
      supportedCollectionServices: Object.keys(data.supportedCollectionServices).filter(x => data.supportedCollectionServices[x]),
      supportedDeliveryServices: Object.keys(data.supportedDeliveryServices).filter(x => data.supportedDeliveryServices[x]),
      supportedContainerTypes: data.supportedContainerTypes ? Object.keys(data.supportedContainerTypes).filter(x => data.supportedContainerTypes[x]) : []
    }
    const promise = upsertSupplierServiceLoadTransportMut({ variables: { branchServiceId: branchServiceId, data } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Specifikace byla uložena',
      successAction: onClose
    })
  }
}
