import { Maybe, Supplier } from '../../graphql/aminTypes'
import { useMap } from 'react-leaflet'
import { useEffect } from 'react'
import L from 'leaflet'

export function MapUpdater({
  customerGps,
  currentSupplier
}: {
  customerGps?: { lat: number; lng: number }
  currentSupplier?: Supplier | Maybe<Supplier> | undefined
}) {
  const map = useMap()

  useEffect(() => {
    if (customerGps && currentSupplier) {
      // Create a LatLng object for the supplier position
      const supplierLatLng = L.latLng(currentSupplier.gps.coordinates[1], currentSupplier.gps.coordinates[0])
      const customerLatLng = L.latLng(customerGps.lat, customerGps.lng)

      const newBounds = L.latLngBounds([customerLatLng, supplierLatLng]) // Create new bounds including customer and supplier
      map.fitBounds(newBounds, { paddingBottomRight: [20, 20], paddingTopLeft: [20, 20] }) // Add some padding for better visibility
    } else if (customerGps) {
      map.flyTo([customerGps.lat, customerGps.lng], 11)
    }
  }, [currentSupplier, customerGps, map])

  return null
}
