import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { ProductWasteEntityWithCapacity } from '../types/product-waste-entity-with-capacity'

export const productContainerCapacityOfWasteDataDefinition: TableDefinitionTypes<ProductWasteEntityWithCapacity> = {
  columnDefinition: {
    id: { hidden: true },
    containerCapacityId: { hidden: true },
    name: { name: 'Typ odpadu' },
    capacityTonne: { name: 'Reálná kapacita [t]', editable: true, type: 'number' },
    calcCapacityTonne: { name: 'Kapacita pro výpočty [t]', editable: true, type: 'number' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '50px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    containerCapacityId: x => x.containerCapacityId,
    name: x => x.name,
    capacityTonne: x => x.capacityTonne ?? '',
    calcCapacityTonne: x => x.calcCapacityTonne ?? '',
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteCapacity',
        icon: 'fe-x',
        title: 'Zrušit kapacitu'
      })
      return ret
    }
  }
}
