import { gql, useQuery } from '@apollo/client'
import { PriceCalculatorResponse } from '../../../graphql/aminTypes'

export const distanceForServiceQuery = gql`
  query distanceForService($supportSupplierBranchId: ID, $supplierSupplierBranchId: ID!, $type: String!, $customerAddress: AddressTypeInclGpsInput!) {
    distanceForService(
      supportSupplierBranchId: $supportSupplierBranchId
      supplierSupplierBranchId: $supplierSupplierBranchId
      type: $type
      customerAddress: $customerAddress
    ) {
      distanceTotal
      durationTotal
      encodedGeometry
      leftTopBound {
        lat
        lng
      }
      rightBottomBound {
        lat
        lng
      }
    }
  }
`

export function useDistanceForServiceQuery() {
  return useQuery<{ distanceForService: PriceCalculatorResponse }>(distanceForServiceQuery, {
    fetchPolicy: 'network-only',
    skip: true
  })
}
