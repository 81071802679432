import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import React from 'react'
import { SupplierSupplierEntity } from '../../../graphql/aminTypes'
import { formatAddressToDisplay } from '../../../services/dataToString/formatAddressToDisplay'
import { FlagIcon } from '../../../components/svgIcons/FlagIcon'
import {
  serviceCategoryOptions,
  ServiceCategoryOrder,
  ServiceCategoryTranslation,
  sortServiceCategoryEntityFn,
  sortServiceCategoryFn
} from '../../../services/types/service-category.type'
import { sortArrayBy } from '../../../services/functions/sortArrayBy'

export const supplierSupplierDataDefinition: TableDefinitionTypes<SupplierSupplierEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      headerStyle: { width: '80px' }
    },
    setupIsFinal: {
      name: 'Finálně nastaveno',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      headerStyle: { width: '80px' }
    },
    name: { name: 'Jméno', filterType: ColumnFilterTypes.customText },
    branches: { name: 'Pobočky' },
    serviceCategory: { name: 'Poskytované služby', filterType: ColumnFilterTypes.customSelect },
    ico: { name: 'IČO' },
    dic: { name: 'DIČ' },
    businessContact: { name: 'Kontakt' },
    invoicingAdr: { name: 'Fakturační adresa' },
    invoicingContact: { name: 'Fakturační kontakt' },
    note: { name: 'Poznámka' },
    flags: { name: 'Příznaky' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '70px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isActive: x => x.isActive,
    setupIsFinal: x => x.branches?.every(x => x.setupIsFinal),
    name: x => x.name,
    branches: x => (
      <>
        <strong>{x.branches?.length}</strong> - {x.branches?.map(y => y.address?.city).join(', ') ?? ''}
      </>
    ),
    serviceCategory: x => {
      return Array.from(
        x.branches?.reduce((acc, y) => (acc = new Set([...(y.branchServices?.map(bs => bs.serviceCategory) ?? []), ...acc])), new Set<string>()) ?? []
      )
        .sort(sortServiceCategoryFn)
        .map(c => ServiceCategoryTranslation[c])
        .join(', ')
    },
    ico: x => x.companyIdentification,
    dic: x => x.companyTaxId,
    businessContact: x =>
      x.businessContact
        ? `${x.businessContact.name ?? ''} ${x.businessContact.email ? `<${x.businessContact.email}>` : ''}  ${
            x.businessContact.phone ? `<${x.businessContact.phone}>` : ''
          }`
        : '',
    invoicingContact: x =>
      x.invoicingContact
        ? `${x.invoicingContact.name ?? ''} ${x.invoicingContact.email ? `<${x.invoicingContact.email}>` : ''}  ${
            x.invoicingContact.phone ? `<${x.invoicingContact.phone}>` : ''
          }`
        : '',
    invoicingAdr: x => (x.invoicingAddress ? formatAddressToDisplay(x.invoicingAddress) : ''),
    note: x => x.note,
    flags: x => (
      <>
        <FlagIcon
          className="fe fe-globe"
          key="web"
          value={!!x.website}
          useEmptyIcon
          desc={x.website ?? ''}
          onClick={() => window.open(x.website?.startsWith('http') ? x.website : `http://${x.website}`, '_blank', 'noreferrer noopener')}
        />
        <FlagIcon
          className="fe fe-trash-2"
          key="iczBranch"
          value={!!x.branches?.some(b => b.icz)}
          useEmptyIcon
          desc={`ICZ pobočky: ${x.branches
            ?.filter(b => !!b.icz)
            .map(b => b.icz)
            .join(', ')}`}
        />
      </>
    ),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'editSupplier',
        icon: 'fe-edit',
        title: 'Upravit dodavatele'
      })
      return ret
    }
  }
}
