export function formatEditorValue(value: any, column: any) {
  const type = column.formatExtraData.type
  if (type === 'boolean') {
    return value === 'true'
  }
  if (type === 'number') {
    return parseFloat(value)
  }

  return value
}
