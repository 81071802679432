import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import React, { useContext } from 'react'
import PageCard from '../../components/Cards/pageCard'
import SupplierServiceEnabler from '../common/components/SupplierServiceEnabler/SupplierServiceEnabler'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { SettingsNode } from './components/SettingsNode'
import { ServiceCategoryTranslation } from '../../services/types/service-category.type'
import FlatRateWasteCollectionEditor from './components/FlatRateWasteCollectionEditor/FlatRateWasteCollectionEditor'
import { useSupplierWasteCollectionFlatRates } from './queries/supplier-waste-collection-flat-rates.query'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { flatRatesDataDefinition } from './services/flatRatesDataDefinition'
import { SupplierWasteCollectionFlatResponse } from '../../graphql/aminTypes'
import BootstrapTable from 'react-bootstrap-table-next'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { WasteLinkComponent } from '../common/WasteLinkComponent/WasteLinkComponent'
import { useDeleteSupplierWasteCollectionFlatRateMutation } from './mutations/delete-supplier-waste-collection-flat-rate.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'

const currentServiceCategory = 'WASTE_COLLECTION_SOLID'
export default function SupplierServiceWasteCollectionSolid() {
  const { state } = useContext(SelectedSupplierModalContext)
  const { showModal, hideModal } = useContext(ModalContext)
  const currentServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === currentServiceCategory)
  const supplierWasteCollectionFlatRatesQR = useSupplierWasteCollectionFlatRates(currentServiceSetting?.id)
  const [deleteSupplierWasteCollectionFlatRateMut, deleteSupplierWasteCollectionFlatRateQR] = useDeleteSupplierWasteCollectionFlatRateMutation()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<SupplierWasteCollectionFlatResponse>(flatRatesDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    actionsReference: {
      deleteSupplierWasteCollectionFlatRate: deleteSupplierWasteCollectionFlatRateHandler
    }
  })

  const tableData = mapDataArrayToTable(supplierWasteCollectionFlatRatesQR.data?.supplierWasteCollectionFlatRates)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(supplierWasteCollectionFlatRatesQR, deleteSupplierWasteCollectionFlatRateQR)}
      headerTitle={ServiceCategoryTranslation[currentServiceCategory]}
    >
      <SupplierServiceEnabler currentServiceSetting={currentServiceSetting} serviceCategory={currentServiceCategory} settingsNode={<SettingsNode />} />
      {currentServiceSetting && (
        <>
          <h2>
            Paušál (flat rate)
            <button type={'button'} onClick={addFlatRateWasteHandler} className="btn btn-primary d-inline-block ml-3">
              Přidat odvoz paušál
            </button>
          </h2>
          <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            classes="table-responsive-lg digiTable"
            noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
            keyField="id"
            data={tableData ? tableData : []}
            columns={columnDefinition}
          />
          <hr />
          <h2>Odvoz na koncovky</h2>
          <WasteLinkComponent branchServiceId={currentServiceSetting?.id} wasteCategory={'SOLID_WASTE'} />
        </>
      )}
    </PageCard>
  )

  function addFlatRateWasteHandler() {
    showModal({
      title: 'Přidat paušální ceník',
      modalContent: (
        <FlatRateWasteCollectionEditor
          branchServiceId={currentServiceSetting?.id}
          onClose={hideModal}
          doNotShowWastes={supplierWasteCollectionFlatRatesQR.data?.supplierWasteCollectionFlatRates.map(x => x.productWaste.id)}
        />
      ),
      hideFooter: true
    })
  }
  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex > 2) return
    const item = supplierWasteCollectionFlatRatesQR.data?.supplierWasteCollectionFlatRates.find(x => x.id === row.id)
    showModal({
      title: 'Editovat paušální ceník',
      modalContent: <FlatRateWasteCollectionEditor branchServiceId={currentServiceSetting?.id} onClose={hideModal} editItem={item} />,
      hideFooter: true
    })
  }

  function deleteSupplierWasteCollectionFlatRateHandler(row: any) {
    const promise = deleteSupplierWasteCollectionFlatRateMut({ variables: { branchServiceId: currentServiceSetting?.id, productWasteId: row.productWasteId } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Paušální ceník byl odstraněn'
    })
  }
}
