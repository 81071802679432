import {gql, useMutation} from '@apollo/client'
import {IMutationResult} from "../../../../../services/types/gqlTypes";
import {contextEndpoint, EndpointEnum} from "../../../../../services/appConfiguration/contextEndpoint";
import {getZpoDetailQuery} from "../query/getZpoDetailQuery";

const performZpoEmailDeliveryMutation = gql`
    mutation performZpoEmailDelivery($orderContentId: Int!) {
        performZpoEmailDelivery(orderContentId: $orderContentId) {
            status {
                reason
                result
            }
        }
    }
`

export function usePerformZpoEmailDelivery(orderContentId: number ) {
    return useMutation<{ performZpoEmailDelivery: IMutationResult }>(performZpoEmailDeliveryMutation, {
        context: contextEndpoint(EndpointEnum.admin),
        refetchQueries:
            [
                {
                    query: getZpoDetailQuery,
                    variables: { orderContentId: orderContentId },
                    context: contextEndpoint(EndpointEnum.admin)
                }
            ]
    })
}
