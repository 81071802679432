import React, { useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import PageCard from '../../components/Cards/pageCard'
import ToggleField from '../../components/formComponents/ToggleField'
import { useSupplierSupplierListQuery } from './queries/supplier-supplier-list.query'
import { GpsCoordinationClass, SupplierSupplierEntity } from '../../graphql/aminTypes'
import { serviceCategoryOptions, ServiceCategoryType } from '../../services/types/service-category.type'
import { defaultValues } from './services/default-values'
import SuppliersMap from './components/SuppliersMap'
import { serviceCategoryColors } from './services/service-category-colors'

export type SupplierMapItem = {
  gps: GpsCoordinationClass
  name: string
  serviceCategory: ServiceCategoryType[]
}

//https://icons8.com/icons/set/map-point
//https://www.svgviewer.dev/s/343244/sand-bucket
export default function SupplierSupplierMap() {
  const [supplierList, setSupplierList] = React.useState<SupplierMapItem[]>([])
  const formMethods = useForm<any>({ defaultValues, mode: 'onChange' })

  let selectedCategories: ServiceCategoryType[] = []
  const selectedCategoriesData = formMethods.watch('serviceCategory')
  if (selectedCategoriesData)
    selectedCategories = Object.keys(formMethods.watch('serviceCategory')).filter(key => formMethods.watch('serviceCategory')[key]) as ServiceCategoryType[]

  const supplierListQR = useSupplierSupplierListQuery({ serviceCategories: selectedCategories?.length > 0 ? selectedCategories : undefined })

  async function reloadSuppliers(data, path) {
    let categories = formMethods.watch('serviceCategory')

    if (data) categories = { ...categories, [path.replace('serviceCategory.', '')]: data }

    const resp = await supplierListQR.refetch({ serviceCategories: Object.keys(categories).filter(key => categories[key]) as ServiceCategoryType[] })
    if (resp.data.supplierSupplierList) {
      let processed: SupplierMapItem[] = []
      for (const supplier of resp.data.supplierSupplierList.items) {
        for (const branch of supplier!.branches!) {
          if (!branch.gps || !branch.branchServices) continue
          processed.push({
            gps: branch.gps,
            name: `${supplier.name} (${branch.address?.city})`,
            serviceCategory: branch.branchServices?.map(item => item.serviceCategory)
          })
        }
      }
      setSupplierList(processed)
    }
  }

  useEffect(() => {
    reloadSuppliers('', '')
  }, [])
  return (
    <PageCard headerTitle={'Mapa dodavatelů'}>
      <FormProvider {...formMethods}>
        <div className="row">
          <div className="col-md-2">
            <h2>Zobrazené služby</h2>
            {serviceCategoryOptions.map((item, index) => (
              <div key={index} className={'d-flex flex-row mb-4'}>
                <ToggleField
                  name={`serviceCategory.${item.value}`}
                  label={item.label}
                  registerOptions={{ onChange: e => reloadSuppliers(e.target.checked, e.target.name) }}
                />
                <div
                  className={'ml-1 mt-1'}
                  dangerouslySetInnerHTML={{
                    __html: `<div style="background-color: ${serviceCategoryColors[item.value]}; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white; margin-right: 8px;"></div>`
                  }}
                />
              </div>
            ))}
          </div>
          <div className="col-md-10">
            <SuppliersMap supplierMapItems={supplierList} />
          </div>
        </div>
      </FormProvider>
    </PageCard>
  )
}
