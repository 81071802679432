import PageCard from '../../components/Cards/pageCard'
import { FormProvider, useForm } from 'react-hook-form'
import React, { useContext, useEffect, useState } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryArrayData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { SupplierItemType, useSuppliersQuery } from './services/suppliersQuery'
import { useSuppliersCountQuery } from './services/suppliersCount'
import { supplierListDataDefinition } from './services/supplierListDataDefinition'
import SupplierPriceListRowExpand from './components/SupplierPriceListRowExpand'
import { useNavigate } from 'react-router-dom'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useDeleteSupplierMutation } from './services/deleteSupplierMutation'
import { useUpdateSupplierDisabledMutation } from './services/updateSupplierDisabledMutation'
import { useFilterOptions4CustomSelectCreator } from '../../services/bootstrapTable/useFilterOptions4CustomSelectCreator'
import LoginEditor from '../../components/LoginEditor/LoginEditor'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { useLoginsQuery } from './services/loginsQuery'
import { useAddSupplierToLoginMutation } from './services/addSupplierToLoginMutation'
import { httpBuildQueryString } from '../../services/functions/httpBuildQuery'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'
import { useHighlightRowAfterReturn } from '../../services/pageHooks/highlightRowAfterReturn'
import { pageCardHeaderButton, PageCardLinkHeaderText } from '../../components/Cards/pageCardHeaderButton'
import highlightDisabledSuppliers from './services/highlightDisabledSuppliers'
import { useClearCalcCacheMutation } from './services/clearCalcCacheMutation'
import ToggleField from '../../components/formComponents/ToggleField'
import { PriceListsExport, SuppliersExport } from './components/export-buttons'
import { useDownloadExport } from '../../hooks/useDownloadExport'

export default function SupplierList() {
  const navigate = useNavigate()
  const formMethods = useForm()
  const download = useDownloadExport()
  const { showModal, hideModal } = useContext(ModalContext)
  const [rowToExpand, setRowToExpand] = useState<number>()
  const [showProductsForDelivery, setShowProductsForDelivery] = useState<boolean>(false)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort } =
    useBootstrapTableDataMapper<SupplierItemType>(supplierListDataDefinition)
  const { createReturnUrl, moveScrollPosition, rowClassForHighlightedRow } = useHighlightRowAfterReturn()

  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(['id', 'name', 'orpName', 'disabled', 'regionCode', 'flags'], nameForGqlTranslation, {
    defaultSort,
    defaultPageSize: 100
  })
  const suppliersQR = useSuppliersQuery(gqlQueryParams, moveScrollPosition)
  const suppliersCountQR = useSuppliersCountQuery(gqlQueryParams)
  const [clearCalcCacheMut, clearCalcCacheQR] = useClearCalcCacheMutation()
  const loginsQR = useLoginsQuery()
  const [addSupplierToLoginMut, addSupplierToLoginQR] = useAddSupplierToLoginMutation()

  const [deleteSupplierMut, deleteSupplierQR] = useDeleteSupplierMutation()
  const [updateSupplierDisabledMut, updateSupplierDisabledQR] = useUpdateSupplierDisabledMutation()
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(pageConfiguration, suppliersCountQR)
  const filterOptions = useFilterOptions4CustomSelectCreator()
  filterOptions.addFilterOption(
    'disabled',
    [
      { key: 'false', value: 'Aktivní' },
      { key: 'true', value: 'Neaktivní' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )
  filterOptions.addFilterOption(
    'flags',
    [
      { key: 'COD', value: 'Akceptují dobírku' },
      { key: 'WO_COD', value: 'Bez dobírky' },
      { key: 'WEEKENDS', value: 'Pracují o víkendech' },
      { key: 'HOLIDAYS', value: 'Pracují o státních svátcích' },
      { key: 'WEEKENDS_AND_HOLIDAYS', value: 'Víkendy + st. svátky' },
      { key: 'PRICELIST_FINAL', value: 'Ceník finální' },
      { key: 'PRICELIST_NOT_FINAL', value: 'Ceník není finální' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    filterDefaultValues: pageConfiguration.filterBy,
    filterOptions4CustomSelectCreator: filterOptions,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    actionsReference: {
      editSupplier: editSupplierHandler,
      deleteSupplier: deleteSupplierHandler,
      enableSupplier: enableSupplierHandler,
      disableSupplier: disableSupplierHandler,
      createNewLogin: createNewLoginHandler,
      showOnMap: showOnMapHandler,
      duplicateSupplier: duplicateSupplierHandler
    }
  })

  let suppliersData: any[] = []
  if (suppliersQR.data && hasQueryArrayData(suppliersQR)) suppliersData = mapDataArrayToTable(suppliersQR.data.suppliers)
  useEffect(() => {
    setShowProductsForDelivery(formMethods.watch('isForCollection'))
  }, [formMethods.watch('isForCollection')])

  return (
    <PageCard
      showLoader={isAnyQueryLoading(
        suppliersQR,
        suppliersCountQR,
        updateSupplierDisabledQR,
        deleteSupplierQR,
        addSupplierToLoginQR,
        loginsQR,
        clearCalcCacheQR
      )}
      headerTitle={'Dodavatelé'}
      cardOptions={
        <>
          <span className="mr-3 mt-2">
            <SuppliersExport download={download} />
          </span>
          <span className="mr-3 mt-2">
            <PriceListsExport download={download} />
          </span>
          <span className="mr-3">{pageCardHeaderButton('Vymazat cache', clearCalcCache, 'clearCache')}</span>
          <span>{pageCardHeaderButton('Přidat', () => navigate('/add-supplier'), 'AddSupplier')}</span>
        </>
      }
    >
      <div className="row">
        <div className="col-md-12 text-right">
          <FormProvider {...formMethods}>
            Ukázat produkty pro odvoz &nbsp; <ToggleField name={'isForCollection'} label={'Ukázat produkty pro rozvoz'} />
          </FormProvider>
        </div>
      </div>
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                rowClasses={rowClassForHighlightedRow(highlightDisabledSuppliers)}
                data={suppliersData}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChange}
                expandRow={packetRowDetail(rowToExpand)}
                {...paginationTableProps}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>
    </PageCard>
  )

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex > 10) return false
    setRowToExpand(rowToExpand === row.id ? undefined : row.id)
  }

  function deleteSupplierHandler(row: any) {
    if (!row.id) return
    if (window.confirm(`Opravdu zrušit dodavatele ${row.name}?`)) {
      const deleteSupplierPromise = deleteSupplierMut({ variables: { supplierId: row.id } })
      processPageOperation({
        promise: deleteSupplierPromise,
        successMessage: `Dodavatel '${row.name}' byl odstraněn.`,
        successAction: refetchData
      })
    }
  }

  function editSupplierHandler(row: any) {
    const fwQueryString = httpBuildQueryString({
      supplierId: row.id,
      returnUrl: createReturnUrl(row.id)
    })
    navigate('/edit-supplier' + fwQueryString)
  }

  function duplicateSupplierHandler(row: any) {
    const fwQueryString = httpBuildQueryString({
      supplierId: row.id,
      duplicate: '1'
    })
    navigate('/edit-supplier' + fwQueryString)
  }

  function disableSupplierHandler(row: any) {
    if (!row.id) return
    switchSupplierHandler(row.id, true)
  }

  function enableSupplierHandler(row: any) {
    if (!row.id) return
    switchSupplierHandler(row.id, false)
  }

  function showOnMapHandler(row: any) {
    if (!row.id) return
    navigate('/supplier-map?sourceId=' + row.id)
  }

  function switchSupplierHandler(supplierId: number, disabled: boolean) {
    const updateSupplierDisabledPromise = updateSupplierDisabledMut({ variables: { supplierId, disabled } })
    processPageOperation({
      promise: updateSupplierDisabledPromise,
      successMessage: `Dodavateli byla změněna dostupnost`,
      successAction: refetchData
    })
  }

  function createNewLoginHandler(row: any) {
    loginsQR.refetch({ email: row.email.split(';')[0].trim() }).then(result => {
      if (result.data.logins.length > 0 && result.data.logins[0].loginToSupplier.findIndex(s => s.supplier.id === row.id) < 0) {
        const promise = addSupplierToLoginMut({
          variables: {
            supplierId: row.id,
            loginId: result.data.logins[0].id
          }
        })
        processPageOperation({
          promise: promise,
          successMessage: `Dodavatel byl přiřazen k existujícímu přístupu ${row.email.split(';')[0]}.`,
          successAction: refetchData
        })
      } else {
        const names = row.contactPerson.split(' ', 2)

        showModal({
          title: 'Přidat přístup',
          hideFooter: true,
          modalContent: (
            <LoginEditor
              onFinish={refetchData}
              loadData={{
                email: result.data.logins.length <= 0 ? row.email.split(';')[0] : '',
                firstname: names[0] ?? '',
                lastname: names[1] ?? ''
              }}
              assignSupplierId={row.id}
            />
          )
        })
      }
    })
  }

  function packetRowDetail(rowId: number | undefined) {
    return {
      renderer: function packetRowDetail(row: any) {
        if (!suppliersQR?.data?.suppliers || rowId === undefined) return
        const supplier = suppliersQR.data?.suppliers.find(x => x.id === rowId)
        if (!supplier) return
        return <SupplierPriceListRowExpand supplier={supplier} showProductsForCollection={!showProductsForDelivery} />
      },
      onlyOneExpanding: true,
      expanded: [rowId],
      expandByColumnOnly: true
    }
  }

  function refetchData() {
    Promise.all([suppliersQR.refetch(), suppliersCountQR.refetch()]).then(hideModal)
  }

  function clearCalcCache() {
    const promise = clearCalcCacheMut()
    processPageOperation({
      promise: promise,
      successMessage: `Cache výpočtů byla vymazána.`
    })
  }
}
